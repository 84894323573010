import React, { FocusEvent, useState } from 'react';
import { FilterConfiguration } from '../types/FilterConfiguration';
import { IconButton, Table, TextInput } from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';
import { FilterConfigurationToggle } from './FilterConfigurationToggle';

export interface FilterConfigurationRowProps {
  filterConfiguration: FilterConfiguration;
  filterConfigurationTitle: string;
  onFilterConfigurationRemoved: () => void;
  onFilterConfigurationChanged: (filterConfiguration: FilterConfiguration) => boolean;
}

const FilterConfigurationRow = (props: FilterConfigurationRowProps): JSX.Element => {
  const [isPositionValid, setIsPositionValid] = useState<boolean>(true);
  const [isShowMoreLimitValid, setIsShorMoreLimitValid] = useState<boolean>(true);

  const handleToggleChange = (property: 'visible' | 'pinned' | 'hidden') => {
    props.onFilterConfigurationChanged({
      ...props.filterConfiguration,
      visibility: property
    });
  };

  const handleNumericFieldChange = (property: keyof FilterConfiguration, event: FocusEvent<HTMLInputElement>) => {
    let updatedFilterConfiguration = {} as any;
    let result: boolean = true;

    const newValue: number = parseInt(event.target.value);

    if (newValue !== props.filterConfiguration[property]) {
      updatedFilterConfiguration[property] = newValue;

      result = props.onFilterConfigurationChanged({
        ...props.filterConfiguration,
        ...updatedFilterConfiguration
      });
    }

    switch (property) {
      case 'position':
        setIsPositionValid(result);
        break;
      case 'showMoreLimit':
        setIsShorMoreLimitValid(result);
        break;
    }

    if (!result) {
      event.target.focus();
    }
  };

  return (
    <Table.Row>
      <Table.Cell>{props.filterConfigurationTitle}</Table.Cell>
      <Table.Cell>
        <TextInput
          data-testid="position-field"
          isInvalid={!isPositionValid}
          type="number"
          defaultValue={props.filterConfiguration.position.toString()}
          onBlur={(event: FocusEvent<HTMLInputElement>) => handleNumericFieldChange('position', event)}
        />
      </Table.Cell>
      <Table.Cell>
        <TextInput
          data-testid="showMoreLimit-field"
          isInvalid={!isShowMoreLimitValid}
          type="number"
          defaultValue={props.filterConfiguration.showMoreLimit.toString()}
          onBlur={(event: FocusEvent<HTMLInputElement>) => handleNumericFieldChange('showMoreLimit', event)}
        />
      </Table.Cell>
      <Table.Cell>
        <FilterConfigurationToggle
          filterConfiguration={props.filterConfiguration}
          handleToggleChange={handleToggleChange}
        />
      </Table.Cell>
      <Table.Cell>
        <IconButton
          data-testid="delete-button"
          variant="transparent"
          icon={<DeleteIcon variant="negative" size="large" />}
          aria-label="delete filter"
          onClick={props.onFilterConfigurationRemoved}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default FilterConfigurationRow;
