export type FilterConfiguration = {
  labelId: string;
  position: number;
  showMoreLimit: number;
  visibility: 'visible' | 'pinned' | 'hidden';
};

export type FilterConfigurationUI = FilterConfiguration & {
  labelTitle: string;
};

export const filterConfigurationPositionCompareFn = (a: FilterConfiguration, b: FilterConfiguration): number => {
  return a.position - b.position;
};
