import React from 'react';
import { ToggleButton, ButtonGroup } from '@contentful/f36-button';
import { PreviewIcon } from '@contentful/f36-icons';
import { Icon } from '@contentful/f36-components';
import { VscPinned } from 'react-icons/vsc';
import { FilterConfiguration } from '../types/FilterConfiguration';

export interface FilterConfigurationToggleProps {
  filterConfiguration: FilterConfiguration;
  handleToggleChange: (property: 'visible' | 'pinned' | 'hidden') => void;
}

export const FilterConfigurationToggle = (props: FilterConfigurationToggleProps): JSX.Element => {
  const changeVisibility = (visibility: 'visible' | 'pinned') => {
    let newVisibility: 'visible' | 'pinned' | 'hidden' = 'visible';

    switch (visibility) {
      case 'visible': {
        newVisibility = ['visible', 'pinned'].includes(props.filterConfiguration.visibility) ? 'hidden' : 'visible';
        break;
      }
      case 'pinned': {
        newVisibility = props.filterConfiguration.visibility === 'pinned' ? 'visible' : 'pinned';

        break;
      }
    }

    props.handleToggleChange(newVisibility);
  };

  return (
    <ButtonGroup>
      <ToggleButton
        isActive={props.filterConfiguration.visibility === 'pinned'}
        icon={<Icon as={VscPinned} />}
        aria-label="Pinned"
        size="small"
        onToggle={() => changeVisibility('pinned')}
      />
      <ToggleButton
        isActive={['visible', 'pinned'].includes(props.filterConfiguration.visibility)}
        icon={<PreviewIcon />}
        aria-label="Visible"
        size="small"
        onToggle={() => changeVisibility('visible')}
      />
    </ButtonGroup>
  );
};
