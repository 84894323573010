import { FilterConfiguration, filterConfigurationPositionCompareFn } from '../types/FilterConfiguration';

export const updateFiltersPositions = (
  filterConfigurations: FilterConfiguration[],
  newFilterPosition: number
): FilterConfiguration[] => {
  const sortedFilterConfigurations: FilterConfiguration[] = filterConfigurations.sort(
    filterConfigurationPositionCompareFn
  );
  let indexToUpdate: number = sortedFilterConfigurations.findIndex(
    (filterConfiguration: FilterConfiguration) => filterConfiguration.position === newFilterPosition
  );

  if (indexToUpdate >= 0) {
    while (indexToUpdate < sortedFilterConfigurations.length) {
      sortedFilterConfigurations[indexToUpdate].position++;

      if (NoMorePositionsNeedToBeUpdated(indexToUpdate, sortedFilterConfigurations)) {
        break;
      }

      indexToUpdate++;
    }
  }

  return sortedFilterConfigurations;
};

function NoMorePositionsNeedToBeUpdated(indexToUpdate: number, sortedFilterConfigurations: FilterConfiguration[]) {
  return (
    indexToUpdate + 1 === sortedFilterConfigurations.length ||
    sortedFilterConfigurations[indexToUpdate + 1].position !== sortedFilterConfigurations[indexToUpdate].position
  );
}
