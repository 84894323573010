import React from 'react';
import { Table } from '@contentful/f36-table';
import { IconButton } from '@contentful/f36-button';
import { PlusCircleIcon } from '@contentful/f36-icons';
import { FilterConfiguration } from '../types/FilterConfiguration';
import { EditorAppSDK, Entry } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';

export interface FilterConfigurationAddRowProps {
  onFilterConfigurationAdded: (pinnedProduct: FilterConfiguration) => boolean;
}

const FilterConfigurationAddRow = (props: FilterConfigurationAddRowProps): JSX.Element => {
  const sdk = useSDK<EditorAppSDK>();

  const loadLabel = () => {
    sdk.dialogs.selectSingleEntry({ contentTypes: ['label'] }).then((element: Object | null) => {
      if (element) {
        const entry = element as Entry;

        const newFilterConfiguration: FilterConfiguration = {
          labelId: entry.fields.labelId[sdk.locales.default],
          position: 1,
          visibility: 'visible',
          showMoreLimit: 25
        };
        props.onFilterConfigurationAdded(newFilterConfiguration);
      }
    });
  };

  return (
    <Table.Row>
      <Table.Cell colSpan={4}></Table.Cell>
      <Table.Cell>
        <IconButton
          data-testid="add-button"
          variant="transparent"
          icon={<PlusCircleIcon variant="primary" size="large" />}
          aria-label="Add filter configuration"
          onClick={loadLabel}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default FilterConfigurationAddRow;
